var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:" mt-2",attrs:{"show":_vm.formShow,"rounded":"sm","no-fade":""}},[_c('b-card',[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("User")])])])])]),_c('validation-observer',{ref:"userRules"},[_c('b-form',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Name"}},[_vm._v("Name")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text"},model:{value:(_vm.userValue.name),callback:function ($$v) {_vm.$set(_vm.userValue, "name", $$v)},expression:"userValue.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Surname","label-for":"surname"}},[_c('validation-provider',{attrs:{"name":"surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","type":"text"},model:{value:(_vm.userValue.surname),callback:function ($$v) {_vm.$set(_vm.userValue, "surname", $$v)},expression:"userValue.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","type":"text"},model:{value:(_vm.userValue.phone),callback:function ($$v) {_vm.$set(_vm.userValue, "phone", $$v)},expression:"userValue.phone"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"text","disabled":_vm.userId > 0 && !_vm.$Can('edit_user_email')},model:{value:(_vm.userValue.email),callback:function ($$v) {_vm.$set(_vm.userValue, "email", $$v)},expression:"userValue.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.userValue.id == null)?_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"text"},model:{value:(_vm.userValue.password),callback:function ($$v) {_vm.$set(_vm.userValue, "password", $$v)},expression:"userValue.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1840047599)})],1)],1):_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"text"},model:{value:(_vm.userValue.password),callback:function ($$v) {_vm.$set(_vm.userValue, "password", $$v)},expression:"userValue.password"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"User Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.name; },"label":"name","clearable":false,"input-id":"role"},model:{value:(_vm.userValue.role),callback:function ($$v) {_vm.$set(_vm.userValue, "role", $$v)},expression:"userValue.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Service Delivery Staff","label-for":"service-staff"}},[_c('b-form-checkbox',{attrs:{"id":"service-staff"},model:{value:(_vm.userValue.service_staff),callback:function ($$v) {_vm.$set(_vm.userValue, "service_staff", $$v)},expression:"userValue.service_staff"}},[_vm._v(" Is Service Delivery Staff? ")])],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Employment Type","label-for":"employment"}},[_c('validation-provider',{attrs:{"name":"employment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.employmentOptions,"clearable":false,"input-id":"status"},model:{value:(_vm.userValue.employment),callback:function ($$v) {_vm.$set(_vm.userValue, "employment", $$v)},expression:"userValue.employment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Department","label-for":"department"}},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{ref:"duplicateDepartments",attrs:{"value-consists-of":"LEAF_PRIORITY","limit":3,"multiple":true,"options":_vm.departments,"required":""},on:{"input":_vm.loadSites},model:{value:(_vm.selectedDepartments),callback:function ($$v) {_vm.selectedDepartments=$$v},expression:"selectedDepartments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Site","label-for":"site"}},[_c('validation-provider',{attrs:{"name":"site","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{ref:"duplicateDepartments",attrs:{"value-consists-of":"LEAF_PRIORITY","limit":3,"multiple":true,"options":_vm.sites,"required":""},model:{value:(_vm.selectedSites),callback:function ($$v) {_vm.selectedSites=$$v},expression:"selectedSites"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Hired On","label-for":"hired_on"}},[_c('validation-provider',{attrs:{"name":"hired on","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"hired_on","config":_vm.flatpickrHiredConfig,"placeholder":"DD/MM/YYYY"},on:{"input":_vm.dateChange},model:{value:(_vm.userValue.hired_on),callback:function ($$v) {_vm.$set(_vm.userValue, "hired_on", $$v)},expression:"userValue.hired_on"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Left On","label-for":"left_on"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"left_on","config":_vm.flatpickrLeftConfig,"placeholder":"DD/MM/YYYY"},model:{value:(_vm.userValue.left_on),callback:function ($$v) {_vm.$set(_vm.userValue, "left_on", $$v)},expression:"userValue.left_on"}})],1)],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[(_vm.userId == null)?_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save ")]):_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }