<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class=" mt-2">
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">User</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="userRules">
        <b-form>
          <b-row class="mt-2">
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="Name">Name</label>
                <validation-provider
                  name="name"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="userValue.name"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Surname" label-for="surname">
                <validation-provider
                  name="surname"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="surname"
                    type="text"
                    v-model="userValue.surname"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Phone -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Phone" label-for="phone">
                <b-form-input
                  id="phone"
                  type="text"
                  v-model="userValue.phone"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  name="email"
                  #default="{ errors }"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    type="text"
                    :disabled="userId > 0 && !$Can('edit_user_email') "
                    v-model="userValue.email"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.id == null">
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  name="password"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    type="text"
                    v-model="userValue.password"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-else>
              <b-form-group label="Password" label-for="password">
                <b-form-input
                  id="password"
                  type="text"
                  v-model="userValue.password"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="User Role" label-for="role">
                <validation-provider
                  name="role"
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="userValue.role"
                    :options="roleOptions"
                    :reduce="(val) => val.name"
                    label="name"
                    :clearable="false"
                    input-id="role"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group
                label="Service Delivery Staff"
                label-for="service-staff"
              >
                <b-form-checkbox
                  v-model="userValue.service_staff"
                  id="service-staff"
                >
                  Is Service Delivery Staff?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Employment Type" label-for="employment">
                <validation-provider
                  name="employment"
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="userValue.employment"
                    :options="employmentOptions"
                    :clearable="false"
                    input-id="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Department" label-for="department">
                <validation-provider
                  name="department"
                  #default="{ errors }"
                  rules="required"
                >
                  <treeselect
                    ref="duplicateDepartments"
                    v-model="selectedDepartments"
                    value-consists-of="LEAF_PRIORITY"
                    :limit="3"
                    :multiple="true"
                    @input="loadSites"
                    :options="departments"
                    required
                  />

                  <!-- <v-select id="department" v-model="userValue.department_id" :options="departments" :reduce="(dep) => dep.value" @input="loadSites" :clearable="false" required></v-select> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Site" label-for="site">
                <validation-provider
                  name="site"
                  #default="{ errors }"
                  rules="required"
                >
                  <!-- <v-select id="site" v-model="userValue.site_id" :options="sites" :reduce="(ste) => ste.value" :disabled="sites.length == 0" :clearable="false" required></v-select> -->
                  <treeselect
                    ref="duplicateDepartments"
                    v-model="selectedSites"
                    value-consists-of="LEAF_PRIORITY"
                    :limit="3"
                    :multiple="true"
                    :options="sites"
                    required
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Hired On" label-for="hired_on">
                <validation-provider
                  name="hired on"
                  #default="{ errors }"
                  rules="required"
                >
                  <flat-pickr
                    id="hired_on"
                    class="form-control"
                    :config="flatpickrHiredConfig"
                    @input="dateChange"
                    placeholder="DD/MM/YYYY"
                    v-model="userValue.hired_on"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Left On" label-for="left_on">
                <flat-pickr
                  id="left_on"
                  class="form-control"
                  :config="flatpickrLeftConfig"
                  placeholder="DD/MM/YYYY"
                  v-model="userValue.left_on"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-5">
              <b-button
                v-if="userId == null"
                type="submit"
                @click.prevent="formSubmitted"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-else
                type="submit"
                @click.prevent="formSubmitted"
                variant="primary"
                class="mr-1 float-right"
              >
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCol,
  BRow,
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  VBModal,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import store from "@/store";
import userStoreModule from "../userStoreModule";
import ImageCropper from "@core/components/image-cropper/ImageCropper.vue";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Treeselect,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var userValue = {
      name: null,
      surname: null,
      email: null,
      role: null,
      password: null,
      phone: null,
      employment: null,
      hired_on: null,
      left_on: null,
      status: "active",
      department_id: null,
      site_id: null,
      service_staff: true,
      site_ids: [],
      department_ids: [],
    };

    const statusOptions = [
      { label: "Active", value: "active" },
      { label: "Passive", value: "passive" },
    ];

    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      selectedDepartments: [],
      selectedSites: [],
      userValue: userValue,
      originalEmail : null,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      roleOptions: [],
      departments: [],
      sites: [],
      employmentOptions: ["Full Time", "Part Time"],
      statusOptions,
      userId: null,
      formShow: false,
      flatpickrHiredConfig: {
        dateFormat: "d/m/Y",

        enable: [
          {
            from: "01/01/2024",
            to: "31/12/2029",
          },
        ],
        disable: [
          function(date) {
            return date.getDay() === 0 || date.getDay() === 6;
          },
        ],

        locale: {
          firstDayOfWeek: 1,
        },
      },

      flatpickrLeftConfig: {
        dateFormat: "d/m/Y",

        enable: [
          {
            from: "01/01/2024",
            to: "31/12/2029",
          },
        ],
        disable: [
          function(date) {
            return date.getDay() === 0 || date.getDay() === 6;
          },
        ],

        locale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },

  created() {
    this.getRoles();
    this.getDepartments();
  },

  watch: {},

  methods: {
    dateChange() {
      const sss = this.userValue.left_on;

      const dateParts = this.userValue.hired_on.split("/");
      const day = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) / 1;
      const year = parseInt(dateParts[2]);
      const nextDay = new Date(year, month, day + 1);
      const nextDayFormatted = `${nextDay.getDate()}/${nextDay.getMonth()}/${nextDay.getFullYear()}`;
      this.flatpickrLeftConfig = {
        dateFormat: "d/m/Y",
        enable: [
          {
            from: nextDayFormatted,
            to: "31/12/2029",
          },
        ],
        disable: [
          function(date) {
            return date.getDay() === 0 || date.getDay() === 6;
          },
        ],
        locale: {
          firstDayOfWeek: 1,
        },
      };

      if (this.userValue.hired_on != null) {
        this.userValue.left_on = null;
      } else {
        this.userValue.left_on = sss;
      }
    },
    getRoles() {
      this.formShow = true;
      store
        .dispatch("user/fetchRoles")
        .then((res) => {
          this.roleOptions = res.data.data;

          let filteredRoles = res.data.data.filter(
            (role) => role.name !== "supermanager"
          );

          // Additional filtering based on user role
          if (
            this.userData.role !== "supermanager" &&
            this.userData.role !== "admin"
          ) {
            filteredRoles = filteredRoles.filter(
              (role) => role.name !== "admin"
            );
          }

          this.roleOptions = filteredRoles;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    getDepartments() {
      store
        .dispatch("user/getAllDepartments")
        .then((res) => {
          this.departments = res.data.map((department) => ({
            id: department.id,
            label: department.title,
            sites: department.sites ?? [],
          }));
          this.getUserById();

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "user-list" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    loadSites() {
      this.userValue.site_id = null;
      const selectedDepartmentId = this.userValue.department_id;

      const selectedDepartment = this.departments.filter((department) =>
        this.selectedDepartments.includes(department.id)
      );
      if (selectedDepartment) {
        this.sites = [];
        selectedDepartment.forEach((element) => {
          element.sites.forEach((site) => {
            this.sites.push({
              id: site.id,
              label: site.title,
            });
          });
        });
      } else {
        this.sites = [];
      }
    },

    getUserById() {
      if (router.currentRoute.params.id) {
        this.userId = parseInt(router.currentRoute.params.id);

        store
          .dispatch("user/fetchUser", { id: router.currentRoute.params.id })
          .then((res) => {
            this.userValue = res.data;
            this.originalEmail = this.userValue.email

            const selectedDepartmentId = this.userValue.departmentIds;
            const selectedDepartment = this.departments.filter((department) =>
              selectedDepartmentId.includes(department.id)
            );
            this.selectedDepartments = [];

            selectedDepartment.forEach((element) => {
              this.selectedDepartments.push(element.id);
            });
            this.selectedSites = [];
            selectedDepartment.forEach((element) => {
              element.sites.forEach((site) => {
                if (this.userValue.siteIds.includes(site.id)) {
                  this.selectedSites.push(site.id);
                }
              });
            });
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: error,
                icon: "XIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.userRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            this.userValue.department_ids = this.selectedDepartments;
            this.userValue.site_ids = this.selectedSites;
            let userCheck = 'no';

            if(this.originalEmail != this.userValue.email){
              store
            .dispatch("user/userCheck", { email: this.userValue.email })
              .then((response) => {
                userCheck=response.data;
            })
            }

                if(userCheck == 'no'){
                  store
              .dispatch("user/updateUser", this.userValue)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful",
                    text: "✔️ User Update Successful",
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                });
                // this.formShow = false;
                router.push({ name: "user-list" });
              })
              .catch((error) => {
                console.log(error);
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    text: "❌ Please try again or report an issue to support.",
                    icon: "XIcon",
                    variant: "danger",
                  },
                });
              });
            }
            else {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "This email address exists",
                      text: "Please enter another email address.",
                      icon: "RefreshCcwIcon",
                      variant: "warning",
                    },
                  });
                }
               

            
          } else {
            store
              .dispatch("user/userCheck", { email: this.userValue.email })
              .then((res) => {
                if (res.data == "no") {
                  this.userValue.department_ids = this.selectedDepartments;
                  this.userValue.site_ids = this.selectedSites;

                  store
                    .dispatch("user/addUser", this.userValue)
                    .then((res) => {
                      console.log(res.data);
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Successful",
                          text: "✔️ User Add Successful",
                          icon: "ThumbsUpIcon",
                          variant: "success",
                        },
                      });
                      // this.formShow = false;
                      router.push({ name: "user-list" });
                    })
                    .catch((error) => {
                      console.log(error);
                      this.formShow = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Error",
                          text:
                            "❌ Please try again or report an issue to support.",
                          icon: "XIcon",
                          variant: "danger",
                        },
                      });
                    });
                } else {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "This email address exists",
                      text: "Please enter another email address.",
                      icon: "RefreshCcwIcon",
                      variant: "warning",
                    },
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    text: "❌ Please try again or report an issue to support.",
                    icon: "XIcon",
                    variant: "danger",
                  },
                });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
